// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var IChing$ReactTemplate = require("./IChing.bs.js");

function YiLoader(Props) {
  var size = Props.size;
  var match = React.useState((function () {
          return IChing$ReactTemplate.divine(/* () */0);
        }));
  var changeHexagram = match[1];
  React.useEffect((function () {
          var timerId = setInterval((function (param) {
                  return Curry._1(changeHexagram, (function (param) {
                                return IChing$ReactTemplate.divine(/* () */0);
                              }));
                }), 200);
          return (function (param) {
                    clearInterval(timerId);
                    return /* () */0;
                  });
        }));
  return React.createElement(IChing$ReactTemplate.make, {
              size: size,
              hexagram: match[0]
            });
}

var make = YiLoader;

exports.make = make;
/* react Not a pure module */
