// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var A$ReactTemplate = require("./A.bs.js");

function Burger(Props) {
  var menuOpen = Props.menuOpen;
  var toggleMenu = Props.toggleMenu;
  var tmp;
  switch (menuOpen) {
    case 0 : 
        tmp = "sio__burger-container";
        break;
    case 1 : 
        tmp = "sio__burger-container sio__burger-container--open";
        break;
    case 2 : 
        tmp = "sio__burger-container sio__burger-container--closed";
        break;
    
  }
  var tmp$1;
  switch (menuOpen) {
    case 0 : 
        tmp$1 = "sio__burger-panel";
        break;
    case 1 : 
        tmp$1 = "sio__burger-panel sio__burger-panel--open";
        break;
    case 2 : 
        tmp$1 = "sio__burger-panel sio__burger-panel--closed";
        break;
    
  }
  return React.createElement("div", {
              className: tmp,
              onWheel: (function (e) {
                  e.preventDefault();
                  return /* () */0;
                })
            }, React.createElement("div", {
                  className: tmp$1
                }, React.createElement(A$ReactTemplate.make, {
                      href: "/home",
                      children: "HOME",
                      onClick: (function (param) {
                          return Curry._1(toggleMenu, /* () */0);
                        })
                    }), React.createElement(A$ReactTemplate.make, {
                      href: "/about",
                      children: "ABOUT",
                      onClick: (function (param) {
                          return Curry._1(toggleMenu, /* () */0);
                        })
                    }), React.createElement(A$ReactTemplate.make, {
                      href: "/contact",
                      children: "CONTACT",
                      onClick: (function (param) {
                          return Curry._1(toggleMenu, /* () */0);
                        })
                    })));
}

var make = Burger;

exports.make = make;
/* react Not a pure module */
