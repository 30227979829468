// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var Random = require("bs-platform/lib/js/random.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");

function flatMap(f, xs) {
  return List.flatten(List.map(f, xs));
}

function addYinYang(l) {
  return /* :: */[
          /* :: */[
            /* Yin */0,
            l
          ],
          /* :: */[
            /* :: */[
              /* Yang */1,
              l
            ],
            /* [] */0
          ]
        ];
}

var binaryToKingWen = /* :: */[
  2,
  /* :: */[
    23,
    /* :: */[
      8,
      /* :: */[
        20,
        /* :: */[
          16,
          /* :: */[
            35,
            /* :: */[
              45,
              /* :: */[
                12,
                /* :: */[
                  15,
                  /* :: */[
                    52,
                    /* :: */[
                      39,
                      /* :: */[
                        53,
                        /* :: */[
                          62,
                          /* :: */[
                            56,
                            /* :: */[
                              31,
                              /* :: */[
                                33,
                                /* :: */[
                                  7,
                                  /* :: */[
                                    4,
                                    /* :: */[
                                      29,
                                      /* :: */[
                                        59,
                                        /* :: */[
                                          40,
                                          /* :: */[
                                            64,
                                            /* :: */[
                                              47,
                                              /* :: */[
                                                6,
                                                /* :: */[
                                                  46,
                                                  /* :: */[
                                                    18,
                                                    /* :: */[
                                                      48,
                                                      /* :: */[
                                                        57,
                                                        /* :: */[
                                                          32,
                                                          /* :: */[
                                                            50,
                                                            /* :: */[
                                                              28,
                                                              /* :: */[
                                                                44,
                                                                /* :: */[
                                                                  24,
                                                                  /* :: */[
                                                                    27,
                                                                    /* :: */[
                                                                      3,
                                                                      /* :: */[
                                                                        42,
                                                                        /* :: */[
                                                                          51,
                                                                          /* :: */[
                                                                            21,
                                                                            /* :: */[
                                                                              17,
                                                                              /* :: */[
                                                                                25,
                                                                                /* :: */[
                                                                                  36,
                                                                                  /* :: */[
                                                                                    22,
                                                                                    /* :: */[
                                                                                      63,
                                                                                      /* :: */[
                                                                                        37,
                                                                                        /* :: */[
                                                                                          55,
                                                                                          /* :: */[
                                                                                            30,
                                                                                            /* :: */[
                                                                                              49,
                                                                                              /* :: */[
                                                                                                13,
                                                                                                /* :: */[
                                                                                                  19,
                                                                                                  /* :: */[
                                                                                                    41,
                                                                                                    /* :: */[
                                                                                                      60,
                                                                                                      /* :: */[
                                                                                                        61,
                                                                                                        /* :: */[
                                                                                                          54,
                                                                                                          /* :: */[
                                                                                                            38,
                                                                                                            /* :: */[
                                                                                                              58,
                                                                                                              /* :: */[
                                                                                                                10,
                                                                                                                /* :: */[
                                                                                                                  11,
                                                                                                                  /* :: */[
                                                                                                                    26,
                                                                                                                    /* :: */[
                                                                                                                      5,
                                                                                                                      /* :: */[
                                                                                                                        9,
                                                                                                                        /* :: */[
                                                                                                                          34,
                                                                                                                          /* :: */[
                                                                                                                            14,
                                                                                                                            /* :: */[
                                                                                                                              43,
                                                                                                                              /* :: */[
                                                                                                                                1,
                                                                                                                                /* [] */0
                                                                                                                              ]
                                                                                                                            ]
                                                                                                                          ]
                                                                                                                        ]
                                                                                                                      ]
                                                                                                                    ]
                                                                                                                  ]
                                                                                                                ]
                                                                                                              ]
                                                                                                            ]
                                                                                                          ]
                                                                                                        ]
                                                                                                      ]
                                                                                                    ]
                                                                                                  ]
                                                                                                ]
                                                                                              ]
                                                                                            ]
                                                                                          ]
                                                                                        ]
                                                                                      ]
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ]
                                                                            ]
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var xs_000 = /* :: */[
  /* Yin */0,
  /* [] */0
];

var xs_001 = /* :: */[
  /* :: */[
    /* Yang */1,
    /* [] */0
  ],
  /* [] */0
];

var xs = /* :: */[
  xs_000,
  xs_001
];

var xs$1 = List.flatten(List.map(addYinYang, xs));

var xs$2 = List.flatten(List.map(addYinYang, xs$1));

var xs$3 = List.flatten(List.map(addYinYang, xs$2));

var xs$4 = List.flatten(List.map(addYinYang, xs$3));

var hexagrams = List.map((function (prim) {
        return prim[1];
      }), List.sort((function (a, b) {
            return a[0] - b[0] | 0;
          }), Belt_List.zip(binaryToKingWen, List.flatten(List.map(addYinYang, xs$4)))));

function coinToss(param) {
  return Random.$$int(2) + 2 | 0;
}

function getLine(param) {
  var result = ((Random.$$int(2) + 2 | 0) + (Random.$$int(2) + 2 | 0) | 0) + (Random.$$int(2) + 2 | 0) | 0;
  var switcher = result - 6 | 0;
  if (!(switcher > 2 || switcher < 0) && switcher !== 1) {
    return /* Yin */0;
  } else {
    return /* Yang */1;
  }
}

function divine(param) {
  var hex = List.map((function (param) {
          return getLine(/* () */0);
        }), /* :: */[
        1,
        /* :: */[
          2,
          /* :: */[
            3,
            /* :: */[
              4,
              /* :: */[
                5,
                /* :: */[
                  6,
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]);
  var hindex = List.mapi((function (id, h) {
          return /* tuple */[
                  id,
                  h
                ];
        }), hexagrams);
  return List.find((function (h) {
                  return Caml_obj.caml_equal(h[1], hex);
                }), hindex)[1];
}

function IChing(Props) {
  var match = Props.size;
  var size = match !== undefined ? match : 45;
  var hexagram = Props.hexagram;
  return React.createElement("div", {
              className: "iching__container",
              style: {
                height: String(size - (size / 5 | 0) | 0) + "px",
                width: String(size) + "px"
              }
            }, React.createElement("svg", {
                  className: "iching__svg",
                  viewBox: "0 0 15 12"
                }, $$Array.of_list(List.mapi((function (i2, l) {
                            var offset = String(13 - (Pervasives.abs(6 - i2 | 0) << 1) | 0);
                            if (l) {
                              return React.createElement("g", {
                                          key: String(i2)
                                        }, React.createElement("path", {
                                              d: "M1," + (String(offset) + (" L7," + (String(offset) + "")))
                                            }), React.createElement("path", {
                                              d: "M8," + (String(offset) + (" L14," + (String(offset) + "")))
                                            }));
                            } else {
                              return React.createElement("g", {
                                          key: String(i2)
                                        }, React.createElement("path", {
                                              d: "M1," + (String(offset) + (" L5," + (String(offset) + "")))
                                            }), React.createElement("path", {
                                              d: "M10," + (String(offset) + (" L14," + (String(offset) + "")))
                                            }));
                            }
                          }), hexagram))));
}

var make = IChing;

exports.flatMap = flatMap;
exports.addYinYang = addYinYang;
exports.binaryToKingWen = binaryToKingWen;
exports.hexagrams = hexagrams;
exports.coinToss = coinToss;
exports.getLine = getLine;
exports.divine = divine;
exports.make = make;
/* hexagrams Not a pure module */
