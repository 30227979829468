// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var UrlContext$ReactTemplate = require("./UrlContext.bs.js");

function getHref(_url, href) {
  return href;
}

function A(Props) {
  var href = Props.href;
  var match = Props.className;
  var className = match !== undefined ? match : "";
  var children = Props.children;
  var match$1 = Props.onClick;
  var onClick = match$1 !== undefined ? Caml_option.valFromOption(match$1) : undefined;
  UrlContext$ReactTemplate.useUrl(/* () */0);
  return React.createElement("a", {
              className: className,
              href: href,
              onClick: (function (e) {
                  e.preventDefault();
                  ReasonReactRouter.push(href);
                  if (onClick !== undefined) {
                    return Curry._1(onClick, e);
                  } else {
                    return /* () */0;
                  }
                })
            }, children);
}

var make = A;

exports.getHref = getHref;
exports.make = make;
/* react Not a pure module */
