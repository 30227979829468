// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Menu$ReactTemplate = require("./Menu.bs.js");

function Header(Props) {
  var toggleMenu = Props.toggleMenu;
  return React.createElement("header", {
              className: "sio__header"
            }, React.createElement(Menu$ReactTemplate.make, {
                  toggleMenu: toggleMenu
                }));
}

var make = Header;

exports.make = make;
/* react Not a pure module */
