// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function About(Props) {
  var visible = Props.visible;
  return React.createElement("section", {
              className: visible ? "sio__about" : "sio__about sio__about--hidden"
            }, React.createElement("p", undefined, "Software engineer with several years of experience building cross-platform\n             applications with modern techniques. If you need technical assistance\n             developing a service, system, or product, feel free to reach out ", React.createElement("span", {
                      className: "sio__small-only"
                    }, "."), React.createElement("span", {
                      className: "sio__big-only"
                    }, " using the form below:")));
}

var make = About;

exports.make = make;
/* react Not a pure module */
