// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var IChing$ReactTemplate = require("./IChing.bs.js");

function Menu(Props) {
  var toggleMenu = Props.toggleMenu;
  var match = React.useState((function () {
          return IChing$ReactTemplate.divine(/* () */0);
        }));
  var updateHexagram = match[1];
  var changeHex = function (param) {
    return Curry._1(updateHexagram, (function (param) {
                  return IChing$ReactTemplate.divine(/* () */0);
                }));
  };
  return React.createElement("div", {
              className: "sio__menu-container",
              onClick: (function (param) {
                  return Curry._1(toggleMenu, /* () */0);
                })
            }, React.createElement("div", {
                  className: "sio__menu-iching-small",
                  onClick: changeHex,
                  onMouseOut: changeHex,
                  onMouseOver: changeHex
                }, React.createElement(IChing$ReactTemplate.make, {
                      size: 30,
                      hexagram: match[0]
                    })));
}

var make = Menu;

exports.make = make;
/* react Not a pure module */
