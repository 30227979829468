// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var UrlContext$ReactTemplate = require("./UrlContext.bs.js");

var provider = UrlContext$ReactTemplate.context.Provider;

function UrlProvider(Props) {
  var value = Props.value;
  var children = Props.children;
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

var make = UrlProvider;

exports.provider = provider;
exports.make = make;
/* provider Not a pure module */
