// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Name$ReactTemplate = require("./Name.bs.js");
var About$ReactTemplate = require("./About.bs.js");
var Epics$ReactTemplate = require("../Epics.bs.js");
var Hooks$ReactTemplate = require("../Hooks.bs.js");
var Burger$ReactTemplate = require("./Burger.bs.js");
var Footer$ReactTemplate = require("./Footer.bs.js");
var Header$ReactTemplate = require("./Header.bs.js");
var Loader$ReactTemplate = require("./Loader.bs.js");
var Contact$ReactTemplate = require("./Contact.bs.js");
var UrlProvider$ReactTemplate = require("./UrlProvider.bs.js");

function mapUrlToRoute(url) {
  var match = url[/* path */0];
  if (match) {
    switch (match[0]) {
      case "about" : 
          if (match[1]) {
            return /* Home */0;
          } else {
            return /* About */1;
          }
      case "contact" : 
          if (match[1]) {
            return /* Home */0;
          } else {
            return /* Contact */2;
          }
      case "home" : 
          return /* Home */0;
      default:
        return /* Home */0;
    }
  } else {
    return /* Home */0;
  }
}

function mapUrlToSubmissionState(url) {
  var match = url[/* search */2];
  switch (match) {
    case "success=false" : 
        return /* Failed */2;
    case "success=true" : 
        return /* Success */1;
    default:
      return /* Pending */0;
  }
}

function menuReducer(state, action) {
  if (state !== 1) {
    return /* Open */1;
  } else {
    return /* Closed */2;
  }
}

function submissionReducer(param, action) {
  if (typeof action === "number") {
    return /* record */[/* loading */false];
  } else {
    return /* record */[/* loading */true];
  }
}

var initialSubmissionState = /* record */[/* loading */false];

function Body(Props) {
  var serverUrl = Props.serverUrl;
  var match = React.useReducer(menuReducer, /* Initial */0);
  var menuDispatch = match[1];
  var menuOpen = match[0];
  var match$1 = Hooks$ReactTemplate.useEpicReducer(submissionReducer, Epics$ReactTemplate.rootEpic, initialSubmissionState);
  var submissionDispatch = match$1[1];
  var submit = function (e, m) {
    return Curry._1(submissionDispatch, /* SubmitMessageRequest */[
                e,
                m
              ]);
  };
  var toggleMenu = function (param) {
    return Curry._1(menuDispatch, /* ToggleMenu */0);
  };
  var url = serverUrl !== undefined ? serverUrl : ReasonReactRouter.useUrl(undefined, /* () */0);
  var route = mapUrlToRoute(url);
  var submissionStatus = mapUrlToSubmissionState(url);
  return React.createElement(React.Fragment, undefined, React.createElement(UrlProvider$ReactTemplate.make, {
                  value: url,
                  children: null
                }, React.createElement(Header$ReactTemplate.make, {
                      toggleMenu: toggleMenu
                    }), React.createElement("main", {
                      className: "sio__main"
                    }, React.createElement(Name$ReactTemplate.make, {
                          visible: route === /* Home */0
                        }), React.createElement(About$ReactTemplate.make, {
                          visible: route === /* About */1
                        }), React.createElement(Contact$ReactTemplate.make, {
                          visible: route === /* Contact */2,
                          onSubmit: submit,
                          status: submissionStatus
                        })), React.createElement(Burger$ReactTemplate.make, {
                      menuOpen: menuOpen,
                      toggleMenu: toggleMenu
                    }), React.createElement(Footer$ReactTemplate.make, {
                      route: route,
                      menuOpen: menuOpen
                    })), React.createElement(Loader$ReactTemplate.make, {
                  visible: match$1[0][/* loading */0]
                }));
}

var make = Body;

exports.mapUrlToRoute = mapUrlToRoute;
exports.mapUrlToSubmissionState = mapUrlToSubmissionState;
exports.menuReducer = menuReducer;
exports.submissionReducer = submissionReducer;
exports.initialSubmissionState = initialSubmissionState;
exports.make = make;
/* react Not a pure module */
