// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.js");
var Wonka = require("wonka/src/wonka.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var UseEpicReducer$ReactTemplate = require("./hooks/UseEpicReducer.bs.js");

function submitMessageEpic(actionStream, $$fetch, status) {
  return Wonka.switchMap((function (a) {
                  if (typeof a === "number") {
                    return Wonka.fromList(/* [] */0);
                  } else {
                    var payload = { };
                    payload["email"] = a[0];
                    payload["message"] = a[1];
                    return Wonka.map((function (r) {
                                    var match = Curry._1(status, r);
                                    if (match !== 200) {
                                      return /* SubmitMessageFailure */1;
                                    } else {
                                      return /* SubmitMessageSuccess */0;
                                    }
                                  }))(Wonka.fromPromise(Curry._2($$fetch, "/send-message", Fetch.RequestInit[/* make */0](/* Post */2, {
                                              "Content-Type": "application/json"
                                            }, Caml_option.some(JSON.stringify(payload)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(/* () */0))));
                  }
                }))(Wonka.filter((function (a) {
                      if (typeof a === "number") {
                        return false;
                      } else {
                        return true;
                      }
                    }))(actionStream));
}

function submitMessageCompletionEpic(actionStream, push) {
  return Wonka.filter((function (a) {
                  if (typeof a === "number") {
                    if (a !== 0) {
                      Curry._1(push, "/contact?success=false");
                      return false;
                    } else {
                      Curry._1(push, "/contact?success=true");
                      return false;
                    }
                  } else {
                    return false;
                  }
                }))(actionStream);
}

function rootEpic(actionStream, _stateStream) {
  return UseEpicReducer$ReactTemplate.combineEpics(/* :: */[
              submitMessageEpic(actionStream, (function (prim, prim$1) {
                      return fetch(prim, prim$1);
                    }), (function (prim) {
                      return prim.status;
                    })),
              /* :: */[
                submitMessageCompletionEpic(actionStream, ReasonReactRouter.push),
                /* [] */0
              ]
            ]);
}

var WT = 0;

exports.WT = WT;
exports.submitMessageEpic = submitMessageEpic;
exports.submitMessageCompletionEpic = submitMessageCompletionEpic;
exports.rootEpic = rootEpic;
/* Wonka Not a pure module */
