// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

var defaultUrl = /* record */[
  /* path : :: */[
    "home",
    /* [] */0
  ],
  /* hash */"",
  /* search */""
];

var context = React.createContext(defaultUrl);

function useUrl(param) {
  return React.useContext(context);
}

exports.defaultUrl = defaultUrl;
exports.context = context;
exports.useUrl = useUrl;
/* context Not a pure module */
