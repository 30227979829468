// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Body$ReactTemplate = require("./components/Body.bs.js");

ReactDOMRe.renderToElementWithId(React.createElement(Body$ReactTemplate.make, {
          serverUrl: undefined
        }), "root");

ReasonReactRouter.push("");

/*  Not a pure module */
