// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var UseForm$ReactTemplate = require("./hooks/UseForm.bs.js");
var UseEpicReducer$ReactTemplate = require("./hooks/UseEpicReducer.bs.js");

var useForm = UseForm$ReactTemplate.useForm;

var useEpicReducer = UseEpicReducer$ReactTemplate.useEpicReducer;

exports.useForm = useForm;
exports.useEpicReducer = useEpicReducer;
/* UseForm-ReactTemplate Not a pure module */
