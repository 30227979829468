// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var YiLoader$ReactTemplate = require("./YiLoader.bs.js");

function Loader(Props) {
  var visible = Props.visible;
  return React.createElement("div", {
              className: visible ? "sio__loader" : "sio__loader sio__loader--hidden"
            }, React.createElement("div", {
                  className: "sio__spinner"
                }, React.createElement(YiLoader$ReactTemplate.make, {
                      size: 50
                    })));
}

var make = Loader;

exports.make = make;
/* react Not a pure module */
