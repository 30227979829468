// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Hooks$ReactTemplate = require("../Hooks.bs.js");

function isValidEmail(s) {
  if (s !== undefined) {
    var match = s.match((/[^@]+@[^\.]+\..+/));
    return (
            match === null ? undefined : Caml_option.some(match)
          ) !== undefined;
  } else {
    return true;
  }
}

function isValidMessage(s) {
  if (s !== undefined) {
    return s.length > 0;
  } else {
    return true;
  }
}

function validator(values) {
  var d = { };
  var match = isValidEmail(Js_dict.get(values, "email"));
  if (!match) {
    d["email"] = "bad";
  }
  var match$1 = isValidMessage(Js_dict.get(values, "message"));
  if (!match$1) {
    d["message"] = "bad";
  }
  return d;
}

function isOk(s) {
  return s === undefined;
}

function Contact(Props) {
  var visible = Props.visible;
  var onSubmit = Props.onSubmit;
  var status = Props.status;
  var match = Hooks$ReactTemplate.useForm(undefined, undefined, { }, validator);
  var handleChange = match[2];
  var errors = match[1];
  var values = match[0];
  var email = Js_dict.get(values, "email");
  var message = Js_dict.get(values, "message");
  var emailError = Js_dict.get(errors, "email");
  var messageError = Js_dict.get(errors, "message");
  var tmp;
  switch (status) {
    case 0 : 
        var tmp$1;
        tmp$1 = emailError !== undefined && emailError === "bad" ? "sio__email input-error" : "sio__email";
        var tmp$2;
        tmp$2 = messageError !== undefined && messageError === "bad" ? "sio__message input-error" : "sio__message";
        tmp = React.createElement(React.Fragment, undefined, React.createElement("form", {
                  action: "/send-message-no-js",
                  method: "post",
                  onSubmit: (function (e) {
                      e.preventDefault();
                      if (email !== undefined && message !== undefined) {
                        return Curry._2(onSubmit, email, message);
                      } else {
                        return /* () */0;
                      }
                    })
                }, React.createElement("label", undefined, "Return Email", React.createElement("input", {
                          className: tmp$1,
                          name: "email",
                          type: "email",
                          value: email !== undefined ? email : "",
                          onChange: handleChange
                        })), React.createElement("label", undefined, "Message", React.createElement("textarea", {
                          className: tmp$2,
                          name: "message",
                          value: message !== undefined ? message : "",
                          onChange: handleChange
                        })), React.createElement("button", {
                      className: "sio__submit-btn",
                      disabled: !isOk(emailError) || !isOk(messageError)
                    }, "SUBMIT")));
        break;
    case 1 : 
        tmp = React.createElement(React.Fragment, undefined, React.createElement("svg", {
                  className: "sio__checkmark",
                  height: "32",
                  width: "32",
                  viewBox: "0 0 32 32",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("path", {
                      d: "M11.927 22l-6.882-6.883-3 3L11.927 28 31.204 8.728l-3.001-3.001z",
                      fill: "currentColor"
                    })), React.createElement("p", undefined, "Thank you for your submission. I'll be in touch as soon as possible."));
        break;
    case 2 : 
        tmp = React.createElement(React.Fragment, undefined, React.createElement("svg", {
                  className: "sio__xmark",
                  height: "32",
                  width: "32",
                  viewBox: "0 0 32 32",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("path", {
                      d: "M18.404 16l9.9 9.9-2.404 2.404-9.9-9.9-9.9 9.9L3.696 25.9l9.9-9.9-9.9-9.898L6.1 3.698l9.9 9.899 9.9-9.9 2.404 2.406-9.9 9.898z",
                      fill: "currentColor"
                    })), React.createElement("p", undefined, "Oops, there was an issue sending your message. Please try again later."));
        break;
    
  }
  return React.createElement("section", {
              className: visible ? "sio__contact" : "sio__contact sio__contact--hidden"
            }, tmp);
}

var make = Contact;

exports.isValidEmail = isValidEmail;
exports.isValidMessage = isValidMessage;
exports.validator = validator;
exports.isOk = isOk;
exports.make = make;
/* react Not a pure module */
